<template>
  <div class="list-info">
    <div class="filterBar" style="background: #fff;height: inherit;">
      <div class="party-search">
        <input v-model="searchValue" placeholder="输入名字、有效证件号或者手机号" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
        <div class="search-divider"></div>
        <img class="search-icon" :src="require('@/assets/img/search.png')" />
        <van-icon v-if="searchValue" class="clear-btn" @click="onCancel" name="clear" color="#999" size="16" />
        <div class="search-btn" @click="onSearch">搜索</div>
      </div>
      <van-row>
        <van-col span="8">
          <p @click="partyTypeShow = !partyTypeShow" :style="{color:partyTypeShow?'#387FF5':'#666666'}">{{show.partyType}}
            <img :src="require(`@/assets/img/${partyTypeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="labelShow = !labelShow" :style="{color:labelShow?'#387FF5': '#666666' } ">党员标签
            <img :src="require(`@/assets/img/${labelShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666'}">入党时间
            <img :src="require(`@/assets/img/${joinDateShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
          <!-- <p @click="joinDateShow = !joinDateShow" :style="{color:joinDateShow?'#387FF5':'#666666'}">{{show.joinDate}}
            <img :src="require(`@/assets/img/${joinDateShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p> -->
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="joinDateShow = !joinDateShow"><span>{{dataForm.joinDate == ''?'最早':dataForm.joinDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="joinDateEndShow = !joinDateEndShow"><span>{{dataForm.joinEndDate== ''?'至今':dataForm.joinEndDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <div class="main-content" v-if="!labelShow">
        <div class="top-items" @click="goSearch(item.id,item.depth,item.hasChildren,item.children)" v-for="item in partyList" :key="item.id">
            <div class="item-up">
                <div class="partyName">{{item.name}}</div>
                <div class="communityName">{{item.communityStr}}</div>
            </div>
            <div class="item-down">
                <div class="des">
                    <span v-if="item.depth !== 4">{{item.branchNum}}个</span>{{item.depth === 2 ? '党支部,' : item.depth === 3 ? '党小组,' : ''}}
                    <span>{{item.partyNum}}</span>名党员
                </div>
                <van-icon name="arrow" style="font-size: 14px" />
            </div>
        </div>
    </div>
    <div v-if="!labelShow" class="addBtn" @click="$router.push('/party-personnel-info')">
      <img src="@/assets/img/partyadd.png" alt="" />
    </div>
    <van-pull-refresh v-if="!labelShow" v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
            <div class="info-items" v-for="item in dataList" :key="item.id" @click="goInfo(item.id,true)">
                <div class="info-title">
                    <div class="left-title">
                        <div class="communityName">{{item.communityStr}}-</div>
                        <div class="partyType">{{item.partyType === 1 ? '正式党员' : '预备党员'}}</div>
                    </div>
                    <div class="right-title" v-if="item.isFlow">流动党员</div>
                </div>
                <div class="info-mid">
                    <div class="left-info-wrap">
                        <van-image class="headImg" :src="require('@/assets/img/headImg.png')"></van-image>
                        <div class="detail">
                            <div class="name">
                                {{item.userName}}
                                <span class="post">({{item.postName}})</span>
                            </div>
                            <div class="number">{{item.mobile}}</div>
                            <div class="number">{{item.idNumber}}</div>
                        </div>
                    </div>
                    <a :href="'tel:'+item.mobile" @click.stop=""><van-image class="phoneIcon" :src="require('@/assets/img/dianhua.png')"></van-image></a>
                </div>
                <div class="info-footer">
                    <div class="time">{{item.joinDate}} 入党</div>
                    <!-- <div class="is-show" v-if="partyType != 1 && partyType != 2" @click.stop="upOrDownShow(item.id,item.isShow)">{{item.isShow === 1 ? '下墙' : '上墙'}}</div> -->
                </div>
            </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    </van-pull-refresh>
    <!-- 党员类型 -->
    <van-popup v-model="partyTypeShow" round position="bottom">
      <van-picker show-toolbar :columns="partyTypeList" title="请选择党员类型" value-key="label" @cancel="cancelPartyType" @confirm="onConfirm($event,1)"/>
    </van-popup>
    <!-- 党员标签 -->
    <res-label v-show="labelShow" :list="labelList" @selectLabel="selectLabel" ref="resLabel"></res-label>


    <!-- 入党开始日期 -->
    <van-popup v-model="joinDateShow" round position="bottom">
      <van-datetime-picker type="date" :min-date="minDate" title="选择入党开始日期" @cancel="joinDateShow = false" @confirm="joinDateConfirm"/>
    </van-popup>
    <!-- 入党结束日期 -->
    <van-popup v-model="joinDateEndShow" round position="bottom">
      <van-datetime-picker type="date" :min-date="minDate" title="选择入党结束日期" @cancel="joinDateEndShow = false" @confirm="joinEndDateConfirm"/>
    </van-popup>



    <!-- 入党开始日期 -->
    <!-- <van-popup v-model="joinDateShow" round position="bottom">
      <van-datetime-picker type="date" :min-date="minDate" title="选择入党开始日期" @cancel="cancelJoinDate" @confirm="showEndTime"/>
    </van-popup> -->
    <!-- 入党结束日期 -->
    <!-- <van-popup v-model="joinDateEndShow" round position="bottom">
      <van-datetime-picker type="date" :min-date="minDate" title="选择入党结束日期" @cancel="cancelJoinDate" @confirm="onConfirm($event,3)"/>
    </van-popup> -->
  </div>
</template>

<script>
import {formatterDate} from '@/utils/utils'
import resLabel from './res-label'
export default {
    components: {
        resLabel
    },
    data() {
        return {
            searchValue: '',
            page: 0,
            limit: 10,
            totalCount: 0,
            loading: false,
            finished: false,
            pullLoading: false,
            name: '',
            branchId: '',
            branchDepth: '',
            partyList: [],
            dataList: [],
            dataForm: {
                partyType: '',
                joinDate: '',
                joinEndDate: '',
                labels: '',
                labelCondition: '',
            },
            show: {
                partyType: '党员类型',
                joinDate: '入党日期'
            },
            labelList: [],
            partyTypeShow: false,
            labelShow: false,
            dateBarShow: false,
            joinDateShow: false,
            joinDateEndShow: false,
            partyTypeList: [{value: 1, label: '正式党员'}, {value: 2, label: '预备党员'}],
            // partyType: sessionStorage.getItem('partyType'),
            minDate: new Date(1930, 0, 1)
        }
    },
    mounted() {
        this.getOrgList()
        this.getLabelList()
    },
    methods: {
        getOrgList() {
            this.$http({
                url: this.$http.adornUrl('/wxapp/partyBranch/list'),
                method: 'get',
                params: this.$http.adornParams({
                    page: this.page,
                    limit: this.limit,
                    orgId: this.$globalData.userInfo.orgId,
                    depth: this.branchDepth,
                    name: this.name
                })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    // this.partyList = data.page.list[0].children
                    this.partyList = data.page.list
                    this.branchId = data.page.list[0].id
                    this.branchDepth = data.page.list[0].depth
                    this.getDataList()
                }
            })
        },
        getDataList (branchId,branchDepth) {
            this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,})
            this.page++
            this.$http({
                url: this.$http.adornUrl('/wxapp/party/list'),
                method: 'get',
                params: this.$http.adornParams({
                    page: this.page,
                    limit: this.limit,
                    branchId: branchId || this.branchId,
                    branchDepth: branchDepth || this.branchDepth,
                    searchValue: this.searchValue,
                    joinDateBegin: this.dataForm.joinDate,
                    joinDateEnd: this.dataForm.joinEndDate,
                    label: this.dataForm.labels,
                    partyType:this.dataForm.partyType
                })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    this.pullLoading = false
                    this.totalCount = data.page.totalCount
                    if (this.dataList.length == data.page.totalCount) {
                        this.finished = true
                    }
                    this.dataList = this.dataList.concat(data.page.list)
                    this.loading = false;
                }
                this.$toast.clear()
            },err => {this.$toast.clear()})
        },
        getChildrenList(id,depth) {
            this.$http({
                url: this.$http.adornUrl('/wxapp/partyBranch/getChildrenList'),
                method: 'get',
                params: this.$http.adornParams({
                    depth,
                    id
                })
            }).then(({data}) => {
                this.branchId = id
                this.branchDepth = depth
                if(data && data.code === 0) {
                    this.partyList = data.childrenList
                    this.page = 0
                    this.dataList = []
                    this.getDataList(id,depth)
                }
            })
        },
        // getPartyPeople(branchId,branchDepth) {
        //     this.page++
        //     this.$http({
        //         url: this.$http.adornUrl('/wxapp/party/list'),
        //         method: 'get',
        //         params: this.$http.adornParams({
        //             page: this.page,
        //             limit: this.limit,
        //             branchId,
        //             branchDepth
        //         })
        //     }).then(({data}) => {
        //         if(data && data.code === 0) {
        //             this.dataList = data.page.list
        //         }
        //     })
        //     // this.$http({
        //     //     url: this.$http.adornUrl('/wxapp/party/apppartybrand/list'),
        //     //     method: 'post',
        //     //     params: this.$http.adornParams({
        //     //     page: this.page,
        //     //     limit: this.limit
        //     //     })
        //     // }).then(({data})=> {
        //     //     if (data.code == 0) {
        //         this.pullLoading = false
        //     //     this.totalCount = data.page.totalCount
        //     //     if (this.dataList.length == data.page.totalCount) {
        //             this.finished = true
        //     //     }
        //     //     this.dataList = this.dataList.concat(data.page.list)
        //         this.loading = false;
        //         this.$toast.clear()
        //     //     }
        //     //     this.$toast.clear()
        //     // },err=> {this.$toast.clear()})
        // },
        goSearch(id,depth,hasChildren,children) {
            if(depth !== 4 && children || hasChildren) {
                this.getChildrenList(id,depth)
            }else {
                this.$router.push({path: '/party-personnel-search',query:{id,depth}})
            }

        },
        onSearch () {
            this.page = 0
            this.dataList = []
            this.getDataList()
        },
        onCancel () {
            this.page = 0
            this.searchValue = ''
            this.finished = false
            this.dataList = []
            this.getDataList()
        },

        // 获取党员标签
        getLabelList() {
            // if(sessionStorage.getItem('userType') == 2) {
                // 网格果果
                this.$http({
                    url: this.$http.adornUrl('/wxapp/party/label'),
                    method: 'get',
                }).then(({data}) => {
                    if(data && data.code === 0) {
                        this.labelList = data.labels.filter(item => {
                            if (item.label == '其他') {
                                item.children = item.children.filter(val => {
                                    return val.label == '兴趣特长'
                                })
                            }
                            return item.label == '党员' || item.label == '其他'
                        })
                    }
                })
            // }
            // else if(sessionStorage.getItem('userType') == 1) {
            //     // 居民果果
            //     this.$http({
            //         url: this.$http.adornUrl('/wxapp/party/jmgg/label'),
            //         method: 'get',
            //         params: this.$http.adornParams({
            //             orgId: sessionStorage.getItem('orgId')
            //         })
            //     }).then(({data}) => {
            //         if(data && data.code === 0) {
            //             this.labelList = data.labels
            //         }
            //     })
            // }
        },
        // 标签信息改变
        selectLabel (e) {
            const {selectList, labelCondition} = e
            this.dataForm.labels = selectList
            this.dataForm.labelCondition = labelCondition + ''
            this.labelShow = false
            this.onPullDownRefresh()
        },
        onPullDownRefresh(e) {
            this.finished = false
            this.dataList = []
            this.page = 0;
            this.getDataList()
        },
        goInfo(id,readonly) {
            this.$router.push({path:'party-personnel-info',query:{id,readonly}})
        },
        // 上墙
        upOrDownShow(id,isShow) {
            var ids = [id]
            this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
            this.$http({
            url: this.$http.adornUrl('/wxapp/party/show'),
            method: 'post',
            data: this.$http.adornData(ids, false),
            params: this.$http.adornParams({
              show: isShow === 1 ? 0 : 1
            })
          }).then(({data}) => {
            if(data && data.code === 0) {
                this.$toast.success({
                    message: '操作成功',
                    duration: 1000,
                    onClose: () => {
                        setTimeout(() => {
                            // this.isEdit = true;
                            this.$toast.clear()
                            this.page = 0
                            this.dataList = []
                            this.getDataList()
                        }, 500)
                    }
                })
            }else {
                this.$toast.clear()
                this.$toast.fail(data.msg)
            }
          })
        },
        showEndTime(val) {
            this.dataForm.joinDate = formatterDate(val)
            this.joinDateShow = false
            this.joinDateEndShow = true
        },
        changeDate () {
            this.dataList = []
            this.page = 0
            this.finished = false
            this.getDataList()
        },
        dateClose () {
            this.dataForm = {
                joinDate: '',
                joinEndDate: ''
            }
        },
        joinDateConfirm(value) {
            let d = new Date(this.dataForm.joinEndDate)
            if (this.dataForm.joinEndDate != '' && d.getTime(d) < value.getTime(value)) {
                return this.$toast.fail('开始时间不能比结束时间大哦')
            }
            this.dataForm.joinDate = formatterDate(value)
            this.joinDateShow = false
        },
        joinEndDateConfirm(value) {
            let d = new Date(this.dataForm.joinEndDate)
            if (this.dataForm.joinEndDate != '' && d.getTime(d) > value.getTime(value)) {
                return this.$toast.fail('结束时间不能比开始时间小哦')
            }
            this.dataForm.joinEndDate = formatterDate(value)
            this.joinDateEndShow = false
        },
        onConfirm(val,type) {
            if(type === 1) {
                this.dataForm.partyType = val.value
                this.show.partyType = val.label
                this.partyTypeShow = false
                this.page = 0
                this.dataList = []
                this.getDataList()
            }else if(type === 3) {
                this.dataForm.joinEndDate = formatterDate(val)
                this.show.joinDate = `${this.dataForm.joinDate}至${this.dataForm.joinEndDate}`
                this.joinDateEndShow = false
                this.page = 0
                this.dataList = []
                this.getDataList()
            }
        },
        cancelPartyType() {
            this.show.partyType = '党员类型'
            this.dataForm.partyType = ''
            this.partyTypeShow = false
            this.onPullDownRefresh()
        },
        cancelJoinDate() {
            this.show.joinDate = '入党日期'
            this.dataForm.joinDate = ''
            this.joinDateShow = false
            this.onPullDownRefresh()
        }
    }
}
</script>

<style lang="scss" scoped>
.main-content {
    padding: 0 30px;
}
.top-items {
    width: 690px;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px 0px rgba(214,214,214,0.5000);
    border-radius: 20px;
    margin-top: 24px;
    padding: 0 24px;
    .item-up {
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 0;
        border-bottom: 2px solid #bdbdbd;
        .partyName {
            font-size: 32px;
            font-family: 'PingFangSC-Medium';
            font-weight: 500;
            color: #333333;
        }
        .communityName {
            // width: 136px;
            height: 52px;
            background: rgba(242,76,72,0.1200);
            border-radius: 8px;
            line-height: 52px;
            text-align: center;
            font-size: 24px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #F24C48;
            padding: 0 22px;
        }
    }
    .item-down {
         height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 0;
        .des {
            font-size: 28px;
            font-family: 'PingFangSC-Medium';
            font-weight: 500;
            color: #333333;
        }
    }
}
.info-items {
    width: 750px;
    height: 362px;
    background: #FFFFFF;
    padding: 0 30px;
    margin-top: 24px;
    .info-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 96px;
        border-bottom: 2px solid rgba(0,0,0,0.1000);
        .left-title {
            display: flex;
            font-size: 28px;
            font-weight: 500;
            font-family: 'PingFangSC-Medium';
            .communityName {
                color: #666666;
            }
            .partyType {
                color: #333333;
            }
        }
        .right-title {
            font-size: 24px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #999999;
        }
    }
    .info-mid {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0;
        border-bottom: 2px solid rgba(0,0,0,0.1000);
        .left-info-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 424px;
            height: 136px;
            .headImg {
                width: 100px;
                height: 136px;
            }
            .detail {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 136px;
                .name {
                    font-size: 28px;
                    font-family: 'PingFangSC-Medium';
                    font-weight: 500;
                    color: #333333;
                    .post {
                       color: #666666;
                    }
                }
                .number {
                    font-size: 28px;
                    font-family: 'PingFangSC-Regular';
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
        .phoneIcon {
            width: 40px;
            height: 40px;
        }
    }
    .info-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        .time {
            font-size: 24px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #999999;
        }
        .is-show {
            width: 80px;
            height: 52px;
            background: rgba(242,76,72,0.1200);
            border-radius: 8px;
            font-size: 24px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #F24C48;
            line-height: 52px;
            text-align: center;
        }
    }
}
.list-info {
  .filterBar {
    padding-top: 24px;
  }
  .party-search {
    position: relative;
    margin: 0 auto;
    width: 690px;
    height: 64px;
    background: #F5F5F5;
    border-radius: 20px;
    .search-content {
      padding-top: 16px;
      color: #666;
      padding-left: 76px !important;
      display: block !important;
      width: 480px !important;
      margin: 0 122px 0 30px !important;
      background-color: transparent;
      border: none;
    }
    .search-content::placeholder {
      color: #CCCCCC;
    }
    .clear-btn {
      position: absolute;
      top: 16px;
      right: 135px;
    }
    .search-divider {
      width: 2px;
      height: 32px;
      background: #DBDBDB;
      border-radius: 2px;
      position: absolute;
      left: 80px;
      top: 16px;
    }
    .search-icon {
      position: absolute;
      top: 16px;
      left: 24px;
      width: 32px;
      height: 32px;
    }
    .search-btn {
      font-size: 24px;
      border-radius: 20px;
      text-align: center;
      position: absolute;
      right: 4px;
      top: 4px;
      width: 112px;
      height: 56px;
      line-height: 56px;
      background-color: #F24C48;
      color: #fff;
    }
  }
  .divider {
    width: 100%;
    height: 24px;
  }
}
</style>
